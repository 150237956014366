import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

const MainWrapper = styled.main`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: calc(100% - 80px - 20vw);
  height: 100vh;
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <MainWrapper>
      <div>
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist...</p>
      </div>
    </MainWrapper>
  </Layout>
)

export default NotFoundPage
